import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import Api from '../../../utils/Api';
import constants from '../../../utils/constants';
import $ from 'jquery';
import './form_greve.css';
var geocoder = require('google-geocoder');

var geo = geocoder({
    key: constants.apiKey
});

function Greve(props) {

    const [modalvisible, setmodalvisible] = useState(true);
    const [submit, setsubmit] = useState('Pular');
    const [tel, settel] = useState('');
    const [vaiaderir, setvaiaderir] = useState(false);
    const [estado, setestado] = useState('');
    const [cidade, setcidade] = useState('');
    const [email, setemail] = useState('');
    const [telcontato, settelcontato] = useState(0);
    const [emailcontato, setemailcontato] = useState(0);
    

    function getcidade(e) {
        var cod = e.target.selectedIndex;
        var nome = e.target[cod].label;
        setcidade(nome);
    }

    function contatotel() {
        settelcontato(1);
        setemailcontato(0);
    }

    function contatoemail() {
        settelcontato(0);
        setemailcontato(1);
    }

    function aderir() {
        setsubmit('Responder');
        setvaiaderir(true);
        carregajson('UF');
    }

    function naoaderir() {
        setsubmit('Pular');
        setvaiaderir(false);
    }

    function envia() {

        if (vaiaderir === true && tel === '' && telcontato === 1) {
            alert('Precisa preencher o telefone para enviar resposta');
            return;
        }

        if (vaiaderir === true && email === '' && emailcontato === 1) {
            alert('Precisa preencher o email para enviar resposta');
            return;
        }

        if (emailcontato === 0 && telcontato === 0 && vaiaderir === true ) {
            alert('Precisa preencher algum metódo de contato para enviar resposta');
            return;  
        }

        if (vaiaderir === true && estado === '' && estado === 'UF') {
            alert('Precisa preencher o estado para enviar resposta');
            return;
        }

        if (vaiaderir === true && cidade === '') {
            alert('Precisa preencher o cidade para enviar resposta');
            return;
        } 

        if (vaiaderir === true) {
        geo.find(cidade + ' ' + estado, function(err, res) {
            enviaparaserv(res[0].location.lat, res[0].location.lng, tel, email, estado, cidade);
        });
        }

        setmodalvisible(false);

    }
    
    function enviaparaserv(lat, long, tel, email, estado, cidade){
        Api.greveform(lat, long, tel, email, estado, cidade);
    }


    return ( 
        <>
        <Modal
            visible={modalvisible}
            width="400"
            height="400"
            effect="fadeInUp"            
        >       
            <div class='form'>
                <div class='title'>
                    <label>Você irá aderir a greve?{modalvisible}</label>
                    <br></br>
                    <input onClick={aderir} type='radio' name='aderir'></input>
                    <label>SIM</label>
                    <input onClick={naoaderir} type='radio' name='aderir'></input>
                    <label>NÃO</label>
                    <br></br>

                    <label>Contato:</label>
                    <input onClick={contatotel} type='radio' name='contato'></input>
                    <label>Telefone</label>
                    <input onClick={contatoemail} type='radio' name='contato'></input>
                    <label>E-mail</label>
                    <br></br>

                    <div style={{ opacity: telcontato }}>
                        <label>Telefone:</label>
                        <br></br>
                        <input type='tel' value={tel} onChange={e => settel(e.target.value)}></input>
                    </div>

                    <div style={{ opacity: emailcontato }}>
                        <label>E-mail:</label>
                        <br></br>
                        <input type='email' value={email} onChange={e => setemail(e.target.value)}></input>
                    </div>

                    <div className='campolocal'>
                        <label class='label-info'>Onde você irá parar?</label>
                        <br></br>
                        <select className='UF' onChange={e => setestado(e.target.value)}>
                        </select>
                        <select className='cidade' onChange={e => getcidade(e)}>
                        <option>Selecione cidade</option>
                        </select>
                    </div>
                    <br></br>
                    <button onClick={envia}>{submit}</button>
                </div>                
            </div>
        </Modal> 
        </>
    );


}

function carregajson(id, cidadeid) {
    var html = '';

    $.getJSON('https://raw.githubusercontent.com/StarsUPSistemas/citys/main/citys.json', function(data) {
        html += '<option>' + id + '<option>'
        // console.log(data);

        if (id == 'UF' && cidadeid == null) {
            for (var i = 0; i < data.estados.length; i++){
                html += '<option value=' + data.estados[i].sigla + '>' + data.estados[i].sigla + '</option>';
                // console.log(data.estados[i].sigla)
            }
        } else {
            for (var i = 0; i < data.estados.length; i++) {
                if (data.estados[i].sigla == cidadeid) {
                    for (var j = 0; j < data.estados[i].cidades.length; j++) {
                        html += '<option value=' + data.estados[i].sigla + '>' + data.estados[i].cidades[j] + '</option>'
                    }
                }
            }
        }

        $('.' + id).html(html);
    });
}

$(document).on('change', '.UF', function() {
    var cidadeid = $(this).val();

    if (cidadeid != null) {
        carregajson('cidade', cidadeid);
    }
})

export default Greve;