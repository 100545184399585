import axios from "axios";

export default class GenericApi {

    static async post(rota, params) {
        const resp = await axios.post('https://186.233.231.84:80' + rota, { ...params });
        return resp.data;
    }

    static async get(rota) {
        const resp = await axios.get('https://186.233.231.84:80' + rota);

        return resp.data;
    }
    
};