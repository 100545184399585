import React from 'react';
import constants from '../../utils/constants';
import { GoogleApiWrapper, HeatMap, Map } from 'google-maps-react';
import Greve from './form_greve/form_greve';

const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 0.5)",
    "rgba(0, 191, 255, 0.5)",
    "rgba(0, 127, 255, 0.5)",
    "rgba(0, 63, 255, 0.5)",
    "rgba(0, 0, 255, 0.5)",
    "rgba(0, 0, 223, 0.5)",
    "rgba(0, 0, 191, 0.5)",
    "rgba(0, 0, 159, 0.5)",
    "rgba(0, 0, 127, 0.5)",
    "rgba(63, 0, 91, 0.5)",
    "rgba(127, 0, 63, 0.5)",
    "rgba(191, 0, 31, 0.5)",
    "rgba(255, 0, 0, 0.5)",
];

const mapStyles = {
    width: '100%',
    height: '100%'
};

class Mapadecalor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    
    render() { 
        return ( 
            <>
            <Greve />
            <header class="header">
                <div class="header__container">
    
                    <a href='#' class="header__logo">Mapa Greve dos caminhoneiros 2021</a>

                </div>
            </header>                
                <Map
                    google={this.props.google}
                    zoom={4.5}
                    minZoom={3}
                    style={mapStyles}
                    initialCenter={
                        {
                            lat: -17.6600323,
                            lng: -53.5613896
                        }
                    }
                    streetViewControl={false}
                    mapTypeControl={false}
                >
                    <HeatMap
                        gradient={gradient}
                        positions={this.props.data}
                        opacity={1}
                        radius={20} />

                </Map>

            </>
        );
    }
}
 
// Não pode ser chamado direto para renderizar pois não vai aparecer nada / Deve ser chamado por uma função ou class react que passe as cordenadas do mapa de calor

// export default Mapadecalor;
export default GoogleApiWrapper({
    apiKey: constants.apiKey,
    libraries: ["visualization"]
})(Mapadecalor);