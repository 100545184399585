import GenericApi from "./GenericApi"

export default (class Api extends GenericApi {

    static async login(chave, senha) {
        return await super.post('/login', {chave, senha});
    }

    static async teste(rota) {
        return await super.get('/teste');
    }

    static async greveheatmap(rota) {
        return await super.get(rota);
    };

    static async greveform(lat, long, tel, email, estado, cidade) {
        return await super.post('/greve', {lat, long, tel, email, estado, cidade});
    }
    
})