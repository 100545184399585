import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Mapadecalor from "./heatmap";
import Api from "../../utils/Api";

function Mapsready() {

  const [data, setdata] = useState([]);

  useEffect(() => {
    async function loaddata() {
      const resp = await Api.greveheatmap("/greveheatmap");
      console.log(resp);

      setdata(resp);
    }
    loaddata();
  },[]);

  return <Mapadecalor data={data} />;
}

export default Mapsready;